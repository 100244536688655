<script setup lang="ts">
import FormBuilder from "@/components/FormBuilder/index.vue";
const isOpen = ref(true);
</script>

<template>
  <div class="body">
    <div class="actor mt-60">
      <div class="text">Tomer sisley</div>
      <div class="text">James Franco</div>
    </div>
    <div class="text1">Le prix de l’argent</div>
    <div class="imgSection">
      <img src="/images/largo-winch.png" alt=""/>
    </div>
    <div class="text">Un film d’Olivier Masset-Depasse</div>
    <div class="text2" id="dateCinema">LE 31 JUILLET AU CINÉMA</div>
    <div class="text1 mt-92 ml-330 mr-330">à l’occasion de la sortie du film largo winch le prix de l’argent, tentez de remporter :</div>
    <div class="listPrixRemporter">
      <div class="prixRemporter" >le Remboursement de votre coffre de toit</div>
      <div class="prixRemporter" >Des places de cinéma</div>
      <div class="prixRemporter" >des BD Largo winch</div>
    </div>
    <div class="imgCoffreToit">
      <img src="/images/coffre-toit.png" alt="coffre-toit">
    </div>
    <div class="listImgPrixRemporter mb-92">
      <div class="cinema-tickets-2">
        <img src="/images/ticket-polaire-v2.png" alt="cinema-tickets-2">
      </div>
      <div>
        <img src="/images/bd-largo-winch.png" alt="bd-largo-winch">
      </div>
    </div>
    <div class="text1 ml-500 mr-500 mb-10">Du 1er juin au 15 juillet 2024, pour participer :</div>
    <div class="howToParticipe mb-10">
      <div class="participation">
        <div class="icon-number">
          <UIcon name="i-tabler-circle-number-1" />
        </div>
        <div class="title">Achetez</div>
        <p>
          <span>Félicitations ! Vous venez d’acheter un coffre de toit Polaire.</span> <br/>
          <span class="font-bold txt1">Vous pouvez participer à notre grand jeu concours !</span>
        </p>
      </div>
      <div class="participation">
        <div class="icon-number">
          <UIcon name="i-tabler-circle-number-2" />
        </div>
        <div class="title">Photographiez</div>
        <p>
          <span>Prenez en photo votre ticket de caisse et envoyez-le grâce au formulaire ci-dessous. Gardez votre ticket de caisse jusqu’à la fin de l’opération.</span>
          <br/>
          <span class="font-bold txt2">Tirage au sort : 16/07/2024</span>
        </p>
      </div>
      <div class="participation">
        <div class="icon-number">
          <UIcon name="i-tabler-circle-number-3" />
        </div>
        <div class="title">Jouez</div>
        <span class="font-bold txt3">Tentez de remporter :</span>
        <ol>
          <li>1. Le remboursement de votre coffre de toit</li>
          <li>2. Des places de cinéma</li>
          <li>3. Des BD Largo Winch</li>
        </ol>
      </div>
    </div>
    <div>
      <UModal v-model="isOpen" prevent-close :ui="{ width: 'sm:max-w-[50rem]' }">
        <div class="py-20 px-10 bg-[#000]">
          <div class="mb-5">
            <img class="m-auto" src="/images/largo-winch.png" alt=""/>
          </div>
          <div class="text-center text-[#fff] text1 mb-5">Fin du jeu concours !</div>
          <div class="text-center text-[#fff] font-[Poppins-Regular]">Le jeu concours Polaire X Largo Winch est fini. </div>
          <div class="text-center text-[#fff] font-[Poppins-Regular] mb-5">Merci pour votre participation !</div>
          <div class="text-center text-[#fff] font-[Poppins-Regular] font-semibold">Retrouvez nos produits et dernières actualités sur <a href="https://www.polaire.eu" target="_blank">www.polaire.eu</a></div>
        </div>
      </UModal>
    </div>
    <FormBuilder />
  </div>
</template>

<style scoped>
.body {
  display: flex;
  flex-direction: column;
}

.actor {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  font-family: "Poppins-Regular", sans-serif;
  color: #FEF2E2;
  text-shadow: 0 20px 25px rgba(0, 0, 0, 0.10), 0 10px 10px rgba(0, 0, 0, 0.04);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 18px */
  letter-spacing: 3.6px;
  text-transform: uppercase;
  margin: .5rem auto;
}

.text1 {
  text-align: center;
  /* glow-white */
  text-shadow: 0 0 12px rgba(255, 255, 255, 0.40), 0 20px 25px rgba(0, 0, 0, 0.10), 0 10px 10px rgba(0, 0, 0, 0.04);
  font-family: "Zeroes Two", sans-serif;
  font-size: 52px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 52px */
  letter-spacing: 2.08px;
  text-transform: uppercase;
}

.text2 {
  color: #F3E2B0;
  text-shadow: 0 20px 25px rgba(0, 0, 0, 0.10), 0 10px 10px rgba(0, 0, 0, 0.04);
  font-family: "BebasNeue-Regular", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 28px */
  letter-spacing: 0.56px;
  text-transform: uppercase;
  margin: .5rem auto;
}

.text1 ~ .text1 {
  font-size: 30px;
}

.imgSection {
  margin: .5rem auto;
}

#dateCinema {
  border: 1px solid #F3E2B0;
  padding: 1rem;
}

.listPrixRemporter {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 2rem;
  margin-top: 2rem
}

/*.cinema-tickets-2 {
  width: 30%;
}*/

.prixRemporter {
  border-radius: 4px;
  background: #000;
  padding: 1rem;
  color: #F3E2B0;
  text-align: center;
  text-shadow: 0 20px 25px rgba(0, 0, 0, 0.10), 0 10px 10px rgba(0, 0, 0, 0.04);
  font-family: Poppins-Regular, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 20px */
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

.imgCoffreToit {
  margin: 1rem auto;
}

.listImgPrixRemporter {
  display: flex;
  column-gap: 2rem;
  align-items: center;
  margin: 1rem auto;
  justify-content: center;
}

.text1 ~ .text1 ~ .text1 {
  font-size: 44px;
}

.howToParticipe {
  display: flex;
  column-gap: 2rem;
  justify-content: center;
}

.howToParticipe .participation {
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0 0 12px 0 rgba(255, 255, 255, 0.40), 0 20px 25px 0 rgba(0, 0, 0, 0.10), 0 10px 10px 0 rgba(0, 0, 0, 0.04);
  padding: 1rem;
  color: #000000;
  width: 400px;
}

.howToParticipe .participation .i-tabler-circle-number-1,
.howToParticipe .participation .i-tabler-circle-number-2,
.howToParticipe .participation .i-tabler-circle-number-3 {
  font-size: 2rem;
}

.howToParticipe .participation .title {
  text-align: center;
  text-shadow: 0 0 12px rgba(255, 255, 255, 0.40), 0 20px 25px rgba(0, 0, 0, 0.10), 0 10px 10px rgba(0, 0, 0, 0.04);
  font-family: "Zeroes Two", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 36px */
  text-transform: uppercase;
}

.icon-number {
  text-align: center;
}

.howToParticipe .participation {
  font-family: Poppins-Regular, sans-serif;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
}

.howToParticipe .participation ol {
  list-style-type: none;
  text-align: center;
}

.howToParticipe .participation .font-bold.txt1,
.howToParticipe .participation .font-bold.txt3 {
  font-size: 22px;
}

.howToParticipe .participation .font-bold.txt2 {
  font-size: 18px;
}

.howToParticipe .participation p {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: .1rem;
}

.howToParticipe .participation p span {
  text-align: center;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-92 {
  margin-top: 92px !important;
}

.mb-92 {
  margin-bottom: 92px !important;
}

.ml-330 {
  margin-left: 330px;
}

.mr-330 {
  margin-right: 330px;
}

.ml-500 {
  margin-left: 500px;
}

.mr-500 {
  margin-right: 500px;
}

@media screen and (max-width: 768px) {

  .ml-330 {
    margin-left: 1rem;
  }

  .mr-330 {
    margin-right: 1rem;
  }

  .ml-500 {
    margin-left: 1rem;
  }

  .mr-500 {
    margin-right: 1rem;
  }

  .howToParticipe {
    flex-direction: column;
  }

  .howToParticipe .participation {
    width: auto;
    margin: 1rem;
  }

  .howToParticipe .participation .title {
    font-size: 30px;
  }

  .text1 ~ .text1 ~ .text1 {
    font-size: 28px;
  }

  .text1 {
    font-size: 44px;
  }

  .text {
    text-align: center;
    font-size: 13px;
  }

  .actor {
    flex-direction: column;
  }
}

</style>